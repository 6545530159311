body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    */
  font-family: 'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.company-select.col > div > div > span {
  background-color: white;
  border-color: transparent;
}

.tipo-trabajador-select > div > div > span {
  background-color: white;
  border-color: transparent;
}

.tipo-documento-select > div > div > span {
  background-color: white;
  border-color: transparent;
}

.tipo-numero-liquidaciones-select > div > div > span {
  background-color: white;
  border-color: transparent;
}

ul.stage-docs {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

ul.stage-docs > li {
  border-width: 2px;
  border-color: gray;
  border-style: solid;
  width: 30rem;
  margin-bottom:0.2rem;
}

ul.stage-docs > li > .row {
  margin-left:0;
  margin-right:0;
}

ul.stage-docs > li > .row > .col {
  padding-left:0;
  padding-right:0;
  padding-top:0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.stage-docs > li > .row > .col:nth-child(1) {
  width:2rem;
  max-width: 2rem;
  min-width: 2rem;
  padding-left:0.8rem;
  padding-top:0.3rem;
}

ul.stage-docs > li > .row > .col:nth-child(2) {
  width:26.5rem;
  max-width: 26.5rem;
  min-width: 26.5rem;
}

ul.upload-docs {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

ul.upload-docs > li {
  border-width: 2px;
  border-color: gray;
  border-style: solid;
  width: 67.5rem;
  margin-bottom:0.2rem;
}

ul.upload-docs > li > .row {
  margin-left:0;
  margin-right:0;
}

ul.upload-docs > li > .row > .col {
  padding-left:0;
  padding-right:0;
  padding-top:0.3rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

ul.upload-docs > li > .row > .col:nth-child(1) {
  width:2rem;
  max-width: 2rem;
  min-width: 2rem;
  padding-left:0.8rem;
  padding-right:1.4rem;
}

ul.upload-docs > li > .row > .col:nth-child(2) {
  width:4rem;
  max-width: 4rem;
  min-width: 4rem;
}

ul.upload-docs > li > .row > .col:nth-child(3) {
  width:10rem;
  max-width: 10rem;
  min-width: 10rem;
}

ul.upload-docs > li > .row > .col:nth-child(4) {
  width:6rem;
  max-width: 6rem;
  min-width: 6rem;
}

ul.upload-docs > li > .row > .col:nth-child(5) {
  width:8rem;
  max-width: 8rem;
  min-width: 8rem;
}

ul.upload-docs > li > .row > .col:nth-child(6) {
  width:2rem;
  max-width: 2rem;
  min-width: 2rem;
}

ul.upload-docs > li > .row > .col:nth-child(7) {
  width:15rem;
  max-width: 15rem;
  min-width: 15rem;
}

ul.upload-docs > li > .row > .col:nth-child(8) {
  width:18.5rem;
  max-width: 18.5rem;
  min-width: 18.5rem;
  text-align: end;
  padding-right: 0.5rem;
}

.mantenedor-row{
  border-width: 2px;
  border-color: gray;
  border-style: solid;
  width: 67.5rem;
  margin-bottom:0.2rem;
  height:2.5rem;
  display:flex;
  align-content:center;
}

.edit-obligatorio{
  margin-top:1rem;
}

.edit-obligatorio.input-group > div > span
{
  background-color: white;
  border-color: transparent; 
}

.edit-newpassword{
  margin-left:0.4rem;
  margin-top:0.4rem;
}

.edit-newpassword.input-group > div > span
{
  background-color: white;
  border-color: transparent; 
}

.edit-canuploaddocuments{
  margin-left:0.4rem;
  margin-top:0.4rem;
}

.edit-canuploaddocuments.input-group > div > span
{
  background-color: white;
  border-color: transparent; 
}