body {
  margin: 0;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    */
  font-family: 'Nunito';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  margin-bottom: 0px;
}

.UserDetails {
  margin-top: 40px;
}

.UserDetails img {
  width:100px;
  height:100px;
  border-radius: 50px;
  background-size: 100px;
  object-fit: cover;
}

.UserDetails h1 {
  font-size: 24px;
  color:#293845;
}

.UserDetails h2 {
  font-size: 20px;
  color:#293845;
}

.UserDetails h3 {
  font-size: 20px;
  color:#1AAE9F;
}
.btn-outline-secondary{
  background-color: white;
  color:#675AF5;
  border-color: #C7C3FB;
}
.btn-outline-secondary:hover{
  background-color: #6558F5;
  color:white;
  border-color: #C7C3FB;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6558F5;
  border-color: #C7C3FB ;
}
.btn-link{
  text-decoration: underline;
  text-decoration-color: #C7C3FB;
  color: #6558F5;
  font-weight: 500;
}
.progress{
  height:0.75rem;
}
.progress-bar{
  background-color: #1AAE9F;
}
.table-bordered td, .table-bordered th {
  border: 2px solid #dee2e6;
  font-size: 14px;
  background-color: white;
}
#root .container{
  max-width: 98%;
}
.table-bordered td, .table-bordered th {
  font-size:10px;
}
.form-control {
  font-size:12px;
}
.form-label {
  font-size:12px;
}
.btn {
  font-size:12px;
}

.fake-link{
  background-color:transparent;
  border:0;
  color:#007bff;
}

.fake-link:hover{
  text-decoration: underline;
}